import * as React from 'react';
import {useEffect} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Lesson from "../Lesson";
import {AccordionStyle, haveLessons} from "../../utils";
import {ILesson, ISubtopic} from "../../types";


export default function Subtopic({subtopic, addExpandedLesson, removeExpandedLesson, previewType}: {
    subtopic: ISubtopic,
    addExpandedLesson: any,
    removeExpandedLesson: any,
    previewType?: string,
}) {
    const [expand, setExpand] = React.useState(subtopic.expandAll);
    useEffect(() => {
        if (subtopic.lessons?.length) {
            setExpand(subtopic.expandAll)
        }
        setExpand(subtopic.expandAll)
    }, [subtopic.expandAll]);
    return (
        <Accordion
            expanded={(subtopic.lessons && subtopic.lessons?.length > 0) && expand}
            disabled={!subtopic.lessons || subtopic.lessons?.length === 0}
            sx={[{
                border: 'none !important',
                boxShadow: 'none !important',
                ...AccordionStyle
            }]}
        >
            <AccordionSummary
                sx={[{
                    position: 'relative',
                    left: '-10px',
                    width: 'calc(100% + 10px)',
                    minHeight: '28px',
                    padding: '0',
                    paddingLeft: '20px',
                    ...AccordionStyle,
                    '&: hover': {
                        backgroundColor: '#DCDCDC',
                        '& .MuiTypography-root': {
                            zIndex: '1',
                        }
                    }
                },
                    {
                        '&:before': {
                            content: '""',
                            width: '12px',
                            height: '12px',
                            borderRadius: '6px',
                            // left: '-14px',
                            left: '3px',
                            position: 'relative',
                            display: 'flex',
                            backgroundColor: `transparent`,
                            alignSelf: 'flex-start',
                            marginTop: '7px'
                        },
                        '&.Mui-expanded': {
                            '&:before': {
                                backgroundColor: `${subtopic.color}`,
                            }

                        },
                    }]}
                onClick={(subtopic.lessons?.length === 0 || !subtopic.lessons) ? undefined : () => setExpand(!expand)}
                expandIcon={(subtopic.lessons?.length === 0 || !subtopic.lessons) ? undefined :
                    <ExpandMoreIcon onClick={() => setExpand(!expand)}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography sx={{
                    fontSize: '14px',
                    color: `${haveLessons(subtopic) ? 'black' : 'unset'}`
                }}>{subtopic.name}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{
                padding: '0',
                paddingLeft: '10px',
                ...AccordionStyle
            }}>
                {subtopic.lessons.find((lesson: ILesson) => lesson.for === 'teacher') && (
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        paddingLeft: '40px',
                        color: `${haveLessons(subtopic) ? 'black' : 'unset'}`
                    }}>Teacher</Typography>
                )}
                {subtopic.lessons.filter((lesson: ILesson) => lesson.for === 'teacher').map((lesson: ILesson, index: number) => {
                    return (<Lesson
                        lesson={{...lesson, color: subtopic.color, withSubtopic: true, expandAll: subtopic.expandAll}}
                        addExpandedLesson={addExpandedLesson}
                        removeExpandedLesson={removeExpandedLesson}
                        previewType={previewType}
                        key={lesson.name + index}/>)
                })}
                {subtopic.lessons.find((lesson: ILesson) => lesson.for === 'student') && (
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        paddingLeft: '40px',
                        color: `${haveLessons(subtopic) ? 'black' : 'unset'}`
                    }}>Student</Typography>
                )}
                {subtopic.lessons.filter((lesson: ILesson) => lesson.for === 'student').map((lesson: ILesson, index: number) => {
                    return (<Lesson
                        lesson={{...lesson, color: subtopic.color, withSubtopic: true, expandAll: subtopic.expandAll}}
                        addExpandedLesson={addExpandedLesson}
                        removeExpandedLesson={removeExpandedLesson}
                        previewType={previewType}
                        key={lesson.name + index}/>)
                })}
                {subtopic.lessons.find((lesson: ILesson) => lesson.for === '' || lesson.for === undefined) && (
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        paddingLeft: '40px',
                        color: `${haveLessons(subtopic) ? 'black' : 'unset'}`
                    }}>All</Typography>
                )}
                {subtopic.lessons.filter((lesson: ILesson) => lesson.for === '' || lesson.for === undefined).map((lesson: ILesson, index: number) => {
                    return (<Lesson
                        lesson={{...lesson, color: subtopic.color, withSubtopic: true, expandAll: subtopic.expandAll}}
                        addExpandedLesson={addExpandedLesson}
                        removeExpandedLesson={removeExpandedLesson}
                        previewType={previewType}
                        key={lesson.name + index}/>)
                })}
            </AccordionDetails>
        </Accordion>
    );
}