import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import {
    Card,
    createTheme,
    ImageList,
    ImageListItem,
    ToggleButton,
    ToggleButtonGroup,
    useMediaQuery
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import {ILesson} from "../../types";
import {DATA_URL, SCHEMA_NAME} from "../../App";

const theme = createTheme();

const useStyles = makeStyles({
    slickSlider: {
        width: 'calc(100% - 30px)',
    },
    slickPageNav: {
        bottom: '-30px !important',
        '& li': {
            margin: '0 !important'
        }
    },
    lessonPageThumb: {
        border: '1px solid black',
        borderRadius: '10px'
    },
    listItem: {
        border: '1px solid black',
        borderRadius: '10px',
        width: '220px',
        height: '137px'
    }
});

export default function CustomizedCarouselGalleryList({lesson, previewType}: { lesson: ILesson, previewType?: string }) {
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const classes = useStyles();

    const [view, setView] = React.useState('grid');

    const settings = {
        arrows: true,
        dots: true,
        dotsClass: `slick-dots ${classes.slickPageNav}`,
        infinite: false,
        speed: 500,
        slidesToShow: matchDownLG ? matchDownMd ? 1 : 2 : 3,
        slidesToScroll: 1,
        customPaging: function () {
            return (
                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false"
                     aria-hidden="true" viewBox="0 0 24 24" data-testid="HorizontalRuleIcon">
                    <path fillRule="evenodd" d="M4 11h16v2H4z"></path>
                </svg>
            );
        },
        nextArrow: <KeyboardArrowRightIcon sx={{
            color: 'black',
            background: 'white',
            '&:hover': {
                color: 'black',
            },
        }}/>,
        prevArrow: <KeyboardArrowLeftIcon sx={{
            color: 'black',
            background: 'white',
            '&:hover': {
                color: 'black',
            },
        }}/>
    };

    const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        if (nextView) {
            setView(nextView);
        }
    };

    const items: Array<React.ReactNode> = [];

    for (let i = 0; i < lesson.lessonPages.length; i++) {
        items.push(
            <Card key={i.toString()} sx={{boxShadow: 'none'}}>
                <Grid container spacing={0}
                      sx={{margin: '16px 0', marginRight: '15px', marginBottom: '0', justifyContent: 'center'}}>
                    <a href={lesson.lessonPageUrl + (previewType ? `?${previewType.toLowerCase()}` : '') + '#' + (i + 1)} target="_blank" rel="noreferrer"
                       key={lesson.lessonPageUrl + '#' + (i + 1)}><img
                        src={`${DATA_URL}/${SCHEMA_NAME}/${lesson.lessonPageThumbUrl + '/' + (i + 1) + '.png'}`}
                        alt={'thumb'}
                        width={'220px'}
                        height={'137px'}
                        className={classes.lessonPageThumb}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src="https://www.dummyimage.co.uk/480x270/0F0848/FFFFFF/Image%20temporary%20unavailable/24";
                        }}
                    /></a>
                </Grid>
            </Card>
        );
    }
    return (
        <>
            <Box sx={{
                left: `${lesson.withSubtopic ? '0px' : '10px'}`,
                width: `${lesson.withSubtopic ? '100%' : 'calc(100% - 10px)'}`,
                position: 'relative'
            }}
            >
                <Box sx={{
                    display: lesson.withSlideGrid ? 'flex' : 'none',
                    justifyContent: 'center',
                    marginTop: '10px',
                    left: `${lesson.withSubtopic ? '10px' : '0'}`
                }}
                >
                    <ToggleButtonGroup
                        value={view}
                        exclusive
                        onChange={handleChange}
                    >
                        <ToggleButton value="slide" aria-label="module">
                            <ViewCarouselIcon/>
                        </ToggleButton>
                        <ToggleButton value="grid" aria-label="list">
                            <ViewModuleIcon/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {view === 'slide' && (<Slider {...settings} className={classes.slickSlider}>
                    {items}
                </Slider>)}
                {view === 'grid' && (<Box sx={{left: '-22px', position: 'relative'}}><ImageList
                    sx={{maxWidth: '800px', margin: '16px auto', justifyItems: 'center'}}
                    cols={matchDownLG ? matchDownMd ? 1 : 2 : 3} rowHeight={140}>
                    {[...Array(lesson.lessonPages.length)].map((elem, index) => (
                        <ImageListItem key={lesson.lessonPageThumbUrl + '/' + (index + 1)}
                                       sx={{marginRight: '0px', marginBottom: '10px'}}>
                            <a href={lesson.lessonPageUrl + (previewType ? `?${previewType.toLowerCase()}` : '') + '#' + (index + 1)} target="_blank" rel="noreferrer"><img
                                alt={'thumb'}
                                className={classes.listItem}
                                src={`${DATA_URL}/${SCHEMA_NAME}/${lesson.lessonPageThumbUrl + '/' + (index + 1) + '.png'}?w=164&h=164&fit=crop&auto=format`}
                                loading="lazy"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="https://www.dummyimage.co.uk/480x270/0F0848/FFFFFF/Image%20temporary%20unavailable/24";
                                }}
                            /></a>
                        </ImageListItem>
                    ))}
                </ImageList></Box>)}
            </Box>
        </>
    )
}