import {makeStyles} from "@mui/styles";

import * as React from 'react';
import {styled} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {createTheme, useMediaQuery} from "@mui/material";

interface IHeaderProps {
    pageName: string
    previewType?: string
    setPreviewType: (previewType: string) => void
}

const useStyles = makeStyles({
    headerWrapper: {
        position: 'relative',
    },
    header: {
        fontSize: '54px',
        fontWeight: 'normal',
        margin: '0',
        marginTop: '5px'
    },
    switch: {
        '& .MuiFormControlLabel-label': {
            position: 'absolute',
            right: '90px',
        }
    }
})

const MaterialUISwitch = styled(Switch)(({theme}) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M12 5.9C13.16 5.9 14.1 6.84 14.1 8C14.1 9.16 13.16 10.1 12 10.1C10.84 10.1 9.9 9.16 9.9 8C9.9 6.84 10.84 5.9 12 5.9ZM12 14.9C14.97 14.9 18.1 16.36 18.1 17V18.1H5.9V17C5.9 16.36 9.03 14.9 12 14.9ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13Z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#8796A5',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#000000',
        width: 32,
        height: 32,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: '-2px',
            top: '-3px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent('#fff',)}" d="M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8Z"/><path fill="${encodeURIComponent('#fff',)}" d="M8.63947 14.2479C6.01023 14.7475 4 16.001 4 18V20H20V18C20 16.001 17.9898 14.7475 15.3605 14.2479L12 19L8.63947 14.2479Z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#8796A5',
        borderRadius: 20 / 2,
    },
}));

const theme = createTheme();

function Header(props: IHeaderProps) {

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const onClick = () => {
        if (props.previewType === 'Student') {
            props.setPreviewType('Teacher')
        } else {
            props.setPreviewType('Student')
        }
    }

    const classes = useStyles();

    return (
        <header className={classes.headerWrapper}>
            {props.previewType && (<FormGroup sx={matchDownMd ? {
                width: '100%',
                right: '-30px',
                position: 'relative',
                display: 'inline-block',
                textAlign: 'right'
            } : {position: 'absolute', right: '-30px', top: '10px', 'zIndex': '1'}} className={classes.switch}>
                <FormControlLabel
                    control={<MaterialUISwitch sx={{m: 1}} defaultChecked onClick={onClick}/>}
                    label={props.previewType}
                />
            </FormGroup>)}
            <h1 className={classes.header}>{props.pageName}</h1></header>
    )
}

export default Header