import * as React from 'react';
import {Suspense, useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import Header from "./components/Header";
import LevelTabs from "./components/Tabs";
import './app.css';
import Container from "@mui/material/Container";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {IData} from "./types";

import SearchResults from "./views/SearchResults";

// @ts-ignore
export const SCHEMA_NAME = window.envsSchemaName || 'science';
export const DATA_URL = `https://tools.learnetic.com/muc2`

const JSON_FILE_URL = `${DATA_URL}/${SCHEMA_NAME}.json`

function App() {
    const [data, setData] = useState<IData>();
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [previewType, setPreviewType] = useState<string | undefined>(SCHEMA_NAME.indexOf('mc') !== -1 ? 'Student' : undefined);

    const navigate = useNavigate();

    const getData = () => {
        setLoading(true)
        const requestOptions: { method: string, redirect: RequestRedirect | undefined } = {
            method: "GET",
            redirect: "follow",
        };

        fetch(JSON_FILE_URL, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setData(result)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                setErrorMessage(`Cannot get requested file: ${error}`)
            });
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (search.length) {
            navigate('/search')
        } else {
            navigate('/')
        }
    }, [search, navigate]);

    return (
        <Container className="container">
            {loading && (<Box sx={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                <CircularProgress/>
            </Box>)}
            {errorMessage && <Box>
                <Typography>{errorMessage}</Typography>
            </Box>}
            {!loading && (<><Header pageName={data?.name || ''} setPreviewType={setPreviewType} previewType={previewType}/>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path='/'
                               element={<LevelTabs data={data} search={search} setSearch={setSearch} previewType={previewType}/>}
                        ></Route>
                        <Route path='/search'
                               element={<SearchResults data={data} search={search} setSearch={setSearch} previewType={previewType}/>}
                        ></Route>
                    </Routes>
                </Suspense></>)}
        </Container>
    );
}

export default App;
