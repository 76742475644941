import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LevelView from "../../views/LevelView";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {makeStyles} from '@mui/styles';
import {
    createTheme,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    useMediaQuery
} from "@mui/material";
import {IData, ILevelViewData} from "../../types";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const useStyles = makeStyles({
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: 'black'
        },
        "& .MuiTab-root.Mui-selected": {
            color: 'black'
        }
    },
    tab: {
        "&.MuiTab-root": {
            fontSize: '20px',
            padding: '14px',
            textTransform: 'none',
            color: 'black'
        },
        '&.Mui-disabled': {
            color: 'gray'
        }
    }
})

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabPanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabPanel-${index}`,
    };
}

const theme = createTheme();

export default function LevelTabs({data, search, setSearch, previewType}: {
    data: IData | undefined,
    search: string,
    setSearch: Dispatch<SetStateAction<string>>
    previewType?: string,
}) {

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const [searchValue, setSearchValue] = React.useState('');


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%', marginTop: '25px', position: 'relative'}}>
            <FormControl
                sx={matchDownMd ? {width: '100%'} : {position: 'absolute', right: '0', top: '3px', 'zIndex': '1'}}>
                <InputLabel size="small" htmlFor="search">Search</InputLabel>
                <OutlinedInput
                    onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === 'Enter') {
                            setSearch(searchValue);
                        }
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchValue(event.target.value);
                    }}
                    value={searchValue}
                    size="small"
                    id="search"
                    type={'text'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setSearch(searchValue);
                                }}
                                edge="end"
                            ><FindInPageIcon/></IconButton>
                        </InputAdornment>
                    }
                    label="Search"
                />
            </FormControl>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs variant="scrollable" scrollButtons="auto" className={classes.tabs} value={value}
                      onChange={handleChange} aria-label="level tabs">
                    {data && data.levels.map((level: ILevelViewData, index: number) => {
                        return (<Tab className={classes.tab} label={level.name} {...a11yProps(0)}
                                     key={level.name + index} disabled={level.areas.length === 0}/>)
                    })}
                </Tabs>
            </Box>
            {data && data.levels.map((level: ILevelViewData, index: number) => {
                return (<CustomTabPanel value={value} index={index} key={level.name + index}>
                    <LevelView level={level} previewType={previewType}/>
                </CustomTabPanel>)
            })}
        </Box>
    );
}