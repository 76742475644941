import {ILesson, ISubtopic, ITopic} from "../types";

export const haveLessons = (elem: ITopic | ISubtopic | ILesson): boolean => {
    if ((elem as ITopic).subtopics) {
        for (let i = 0; i < ((elem as ITopic).subtopics as ISubtopic[]).length; i++) {
            for (let j = 0; j < ((elem as ITopic).subtopics as ISubtopic[])[i].lessons.length; j++) {
                if (((elem as ITopic).subtopics as ISubtopic[])[i].lessons[j].lessonPages) {
                    return ((elem as ITopic).subtopics as ISubtopic[])[i].lessons[j].lessonPages.length > 0;
                }
            }
        }
    } else if ((elem as ITopic).lessons) {
        for (let j = 0; j < ((elem as ITopic).lessons as ILesson[]).length; j++) {
            if (((elem as ITopic).lessons as ILesson[])[j].lessonPages) {
                return ((elem as ITopic).lessons as ILesson[])[j].lessonPages.length > 0;
            }
        }
    } else {
        if ((elem as ILesson).lessonPages) {
            return (elem as ILesson).lessonPages.length > 0;
        }
    }
    return false
}

export const AccordionStyle = {
    color: '#A9A9A9',
    margin: '0 !important',
    textAlign: 'left',
    '.Mui-expanded': {
        margin: '0 !important',
        minHeight: '24px !important',
    },
    '.MuiAccordionSummary-content': {
        width: '100%',
        margin: '4px 12px 3px !important',
        minHeight: '14px !important'
    },
    '&:before': {
        display: 'none'
    },
    '&.Mui-disabled': {
        backgroundColor: `transparent`,
        '& .MuiAccordionSummary-root': {
            opacity: '1',
        }
    }
};