import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import {
    Button,
    Chip,
    createTheme,
    Divider,
    FormControl,
    IconButton,
    ImageList,
    ImageListItem,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    useMediaQuery
} from "@mui/material";
import {DATA_URL, SCHEMA_NAME} from "../../App";
import {makeStyles} from "@mui/styles";
import {IData, ILesson, ILevelViewData, ISearchResult, ISubtopic} from "../../types";
import Box from "@mui/material/Box";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Typography from "@mui/material/Typography";
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import {isEqual} from 'lodash';

const theme = createTheme();

const useStyles = makeStyles({
    listItem: {
        border: '1px solid black',
        borderRadius: '10px',
        width: '220px',
        height: '125px'
    }
});

const tocSearch = (data: IData | undefined, search: string) => {
    const results: ISearchResult[] = []
    for (let i = 0; i < (data?.levels as ILevelViewData[]).length; i++) {
        for (let j = 0; j < (data?.levels as ILevelViewData[])[i].areas.length; j++) {
            for (let k = 0; k < (data?.levels as ILevelViewData[])[i].areas[j].chapters.length; k++) {
                for (let l = 0; l < (data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics.length; l++) {
                    if ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].subtopics) {
                        for (let m = 0; m < ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].subtopics as ISubtopic[]).length; m++) {
                            for (let p = 0; p < ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].subtopics as ISubtopic[])[m].lessons.length; p++) {
                                for (let r = 0; r < ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].subtopics as ISubtopic[])[m].lessons[p].lessonPages.length; r++) {
                                    search.split(' ').forEach(item => {
                                        if (((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].subtopics as ISubtopic[])[m].lessons[p].lessonPages[r].keywords.map(keyword => keyword.toLowerCase()).indexOf(item.toLowerCase()) > -1) {
                                            const elem = {
                                                lessonName: ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].subtopics as ISubtopic[])[m].lessons[p].name,
                                                pageTitle: ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].subtopics as ISubtopic[])[m].lessons[p].lessonPages[r].title,
                                                pageThumbUrl: `${((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].subtopics as ISubtopic[])[m].lessons[p].lessonPageThumbUrl}/${r + 1}`,
                                                lessonPageUrl: `${((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].subtopics as ISubtopic[])[m].lessons[p].lessonPageUrl}#${r + 1}`
                                            }
                                            if (!results.find(item => isEqual(elem, item))) {
                                                results.push(elem)
                                            }
                                        }
                                    })

                                }
                            }
                        }
                    }
                    if ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].lessons) {
                        for (let p = 0; p < ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].lessons as ILesson[]).length; p++) {
                            for (let r = 0; r < ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].lessons as ILesson[])[p].lessonPages.length; r++) {
                                search.split(' ').forEach(item => {
                                    if (((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].lessons as ILesson[])[p].lessonPages[r].keywords.map(keyword => keyword.toLowerCase()).indexOf(item.toLowerCase()) > -1) {
                                        const elem = {
                                            lessonName: ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].lessons as ILesson[])[p].name,
                                            pageTitle: ((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].lessons as ILesson[])[p].lessonPages[r].title,
                                            pageThumbUrl: `${((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].lessons as ILesson[])[p].lessonPageThumbUrl}/${r + 1}`,
                                            lessonPageUrl: `${((data?.levels as ILevelViewData[])[i].areas[j].chapters[k].topics[l].lessons as ILesson[])[p].lessonPageUrl}#${r + 1}`
                                        }
                                        if (!results.find(item => isEqual(elem, item))) {
                                            results.push(elem)
                                        }
                                    }
                                })
                            }
                        }
                    }
                }
            }
        }
    }
    return results
}

function SearchResults({data, search, setSearch, previewType}: {
    data: IData | undefined, search: string,
    setSearch: Dispatch<SetStateAction<string>>,
    previewType?: string
}) {
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const [searchValue, setSearchValue] = React.useState(search);

    const classes = useStyles();

    const handleDelete = (item: string) => {
        setSearch(searchValue.split(' ').filter(elem => elem !== item).join(' '))
        setSearchValue(searchValue.split(' ').filter(elem => elem !== item).join(' '))
    }
    return search.length ? (
        <React.Fragment>
            <Box sx={{width: '100%', marginTop: '25px', marginBottom: '17px', position: 'relative'}}>
                <FormControl
                    sx={matchDownMd ? {width: '100%'} : {position: 'absolute', right: '0', top: '3px', 'zIndex': '1'}}>
                    <InputLabel size="small" htmlFor="search">Search</InputLabel>
                    <OutlinedInput
                        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                            if (e.key === 'Enter') {
                                setSearch(searchValue);
                            }
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchValue(event.target.value);
                        }}
                        value={searchValue}
                        size="small"
                        id="search"
                        type={'text'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setSearch(searchValue);
                                    }}
                                    edge="end"
                                ><FindInPageIcon/></IconButton>
                            </InputAdornment>
                        }
                        label="Search"
                    />
                </FormControl>
                <Box>
                    <Button size="small" variant="outlined" startIcon={<ArrowLeftOutlinedIcon/>} sx={{
                        marginRight: '10px'
                    }} onClick={() => setSearch('')}>
                        Back
                    </Button>
                    <Typography sx={{
                        fontSize: '14px',
                        marginRight: '10px',
                        display: 'inline-block'
                    }}>Search result for: </Typography>
                    {search.split(' ').map(item =>
                        (<Chip sx={{
                            marginRight: '10px'
                        }} label={item} variant="outlined" onDelete={() => handleDelete(item)}/>)
                    )}
                </Box>
            </Box>
            <Divider/>
            <Box sx={{position: 'relative'}}><ImageList
                sx={{maxWidth: '1200px', margin: '16px auto', justifyItems: 'center'}}
                cols={matchDownLG ? matchDownMd ? 2 : 3 : 4} rowHeight={185}>
                {tocSearch(data, search).map(lessonPage =>
                    (<><ImageListItem key={lessonPage.lessonPageUrl}
                                      sx={{marginRight: '0px', marginBottom: '10px'}}>
                        <a href={lessonPage.lessonPageUrl + (previewType ? `?${previewType.toLowerCase()}` : '')} target="_blank" rel="noreferrer"><img
                            alt={'thumb'}
                            className={classes.listItem}
                            src={`${DATA_URL}/${SCHEMA_NAME}/${lessonPage.pageThumbUrl + '.png'}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${DATA_URL}/${SCHEMA_NAME}/${lessonPage.pageThumbUrl + '.png'}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            loading="lazy"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src="https://www.dummyimage.co.uk/480x270/0F0848/FFFFFF/Image%20temporary%20unavailable/24";
                            }}
                        /></a>
                        <Typography sx={{
                            maxWidth: '200px',
                            paddingLeft: '10px',
                            fontSize: '14px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}><b>{lessonPage.lessonName}</b>
                        </Typography>
                        <Typography sx={{
                            maxWidth: '200px',
                            paddingLeft: '10px',
                            fontSize: '14px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}>{lessonPage.pageTitle}
                        </Typography>
                    </ImageListItem></>))}
            </ImageList></Box>
        </React.Fragment>
    ) : (<></>)
}

export default SearchResults