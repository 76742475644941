import * as React from 'react';
import {useEffect} from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomizedCarousel from "../CustomizedCarouselGalleryList";
import {AccordionStyle, haveLessons} from "../../utils";
import {ILesson} from "../../types";

export default function Lesson({lesson, addExpandedLesson, removeExpandedLesson, previewType}: {
    lesson: ILesson,
    addExpandedLesson: any,
    removeExpandedLesson: any,
    previewType?: string,
}) {
    const withSlideGrid = false;
    const onChangeExpand = () => {
        setExpand(!expand)
        if (!expand) {
            addExpandedLesson()
        } else {
            removeExpandedLesson()
        }
    }
    const [expand, setExpand] = React.useState(lesson.expandAll);
    useEffect(() => {
        if (lesson.lessonPages.length) {
            setExpand(lesson.expandAll)
        }
    }, [lesson.expandAll]);
    return (
        <Accordion
            expanded={(lesson.lessonPages && lesson.lessonPages.length > 0) && expand}
            disabled={!lesson.lessonPages || lesson.lessonPages.length === 0}
            sx={[{
                border: 'none !important',
                boxShadow: 'none !important',
                ...AccordionStyle
            }]}>
            <AccordionSummary
                sx={[{
                    position: 'relative',
                    left: `${lesson.withSubtopic ? '-20px' : '-10px'}`,
                    width: `${lesson.withSubtopic ? 'calc(100% + 20px)' : 'calc(100% + 10px)'}`,
                    minHeight: '28px',
                    padding: '0',
                    paddingLeft: `${lesson.withSubtopic ? '40px' : '20px'}`,
                    ...AccordionStyle,
                    '&: hover': {
                        backgroundColor: '#DCDCDC',
                    }
                },
                    {
                        '&:before': {
                            content: '""',
                            width: `${lesson.withSubtopic ? '10px' : '12px'}`,
                            height: `${lesson.withSubtopic ? '10px' : '12px'}`,
                            borderRadius: `${lesson.withSubtopic ? '5px' : '6px'}`,
                            // left: `${lesson.withSubtopic ? '-33px' : '-14px'}`,
                            left: '1px',
                            position: 'relative',
                            display: 'flex',
                            backgroundColor: `transparent`,
                            alignSelf: 'flex-start',
                            marginTop: '7px'
                        },
                        '&.Mui-expanded': {
                            '&:before': {
                                backgroundColor: `${lesson.color}`,
                            }

                        },
                    }]}
                onClick={(lesson.lessonPages.length === 0 || !lesson.lessonPages) ? undefined : () => onChangeExpand()}
                expandIcon={(lesson.lessonPages.length === 0 || !lesson.lessonPages) ? undefined :
                    <ExpandMoreIcon onClick={() => onChangeExpand()}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography sx={{
                    fontSize: '14px',
                    color: `${haveLessons(lesson) ? 'black' : 'unset'}`
                }}>{lesson.name}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={[{
                padding: '0',
                paddingLeft: '10px',
                minHeight: withSlideGrid ? '226px' : 'unset',
                ...AccordionStyle
            }
            ]}>
                {lesson.lessonPages.length !== 0 && (
                    <CustomizedCarousel lesson={{...lesson, withSlideGrid: withSlideGrid}} previewType={previewType} />)}
            </AccordionDetails>
        </Accordion>
    );
}