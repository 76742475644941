import * as React from 'react';
import {useEffect} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Subtopic from "../Subtopic";
import Lesson from "../Lesson";
import {ILesson, ISubtopic, ITopic} from "../../types";
import {AccordionStyle, haveLessons} from "../../utils";

export default function Topic({topic, addExpandedLesson, removeExpandedLesson, previewType}: {
    topic: ITopic,
    addExpandedLesson: any,
    removeExpandedLesson: any,
    previewType?: string,
}) {
    const onChangeExpand = () => setExpand(!expand)
    const [expand, setExpand] = React.useState(topic.expandAll);

    useEffect(() => {
        if (topic.subtopics?.find(subtopic => subtopic.lessons.length) || topic.lessons?.length) {
            setExpand(topic.expandAll)
        }
    }, [topic.expandAll]);
    return (
        <Accordion
            expanded={((topic.subtopics && topic.subtopics?.length > 0) || (topic.lessons && topic.lessons?.length > 0)) && expand}
            disabled={(!topic.subtopics || topic.subtopics?.length === 0) && (!topic.lessons || topic.lessons?.length === 0)}
            sx={[{
                border: 'none !important',
                boxShadow: 'none !important',
                borderRadius: '0 !important',
                borderTopRightRadius: topic.index === 0 ? '10px !important' : '0',
                borderBottomRightRadius: topic.index === (topic.amount !== undefined && topic.amount - 1) ? '10px !important' : '0',
                ...AccordionStyle
            }]}>
            <AccordionSummary
                sx={[{
                    minHeight: '28px',
                    padding: '0',
                    ...AccordionStyle,
                    '&: hover': {
                        backgroundColor: '#DCDCDC',
                        '& .MuiTypography-root': {
                            zIndex: '1',
                        }
                    }
                },
                    {
                        '&:before': {
                            content: '""',
                            width: '14px',
                            height: '14px',
                            borderRadius: '7px',
                            left: '5px',
                            position: 'relative',
                            display: 'flex',
                            backgroundColor: `transparent`,
                            alignSelf: 'flex-start',
                            marginTop: '7px'
                        },
                        '&.Mui-expanded': {
                            '&:before': {
                                backgroundColor: `${topic.color}`,
                            }
                        },
                    }]}
                onClick={(topic.subtopics?.length === 0 || !topic.subtopics) && (topic.lessons?.length === 0 || !topic.lessons) ? undefined : () => onChangeExpand()}
                expandIcon={(topic.subtopics?.length === 0 || !topic.subtopics) && (topic.lessons?.length === 0 || !topic.lessons) ? undefined :
                    <ExpandMoreIcon onClick={() => onChangeExpand()}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography sx={{
                    fontSize: '14px',
                    color: `${haveLessons(topic) ? 'black' : 'unset'}`
                }}>{topic.name}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{
                padding: '0',
                paddingLeft: '10px',
                ...AccordionStyle
            }}>
                {topic.subtopics?.map((subtopic: ISubtopic, index: number) => {
                    return (<Subtopic subtopic={{...subtopic, color: topic.color, expandAll: topic.expandAll}}
                                      addExpandedLesson={addExpandedLesson}
                                      removeExpandedLesson={removeExpandedLesson}
                                      previewType={previewType}
                                      key={subtopic.name + index}/>)
                })}
                {topic.lessons?.map((lesson: ILesson, index: number) => {
                    return (<Lesson
                        addExpandedLesson={addExpandedLesson}
                        removeExpandedLesson={removeExpandedLesson}
                        previewType={previewType}
                        lesson={{...lesson, color: topic.color, withSubtopic: false, expandAll: topic.expandAll}}
                        key={lesson.name + index}/>)
                })}
            </AccordionDetails>
        </Accordion>
    );
}