import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Topic from "../Topic";
import {IChapter, ITopic} from "../../types";

export default function Chapter({chapter, color, addExpandedLesson, removeExpandedLesson, previewType}: {
    chapter: IChapter,
    color: string,
    addExpandedLesson: any,
    removeExpandedLesson: any,
    previewType?: string,
}) {
    return (
        <React.Fragment>
            <Grid sx={{marginTop: '15px', marginBottom: '10px', overflow: 'hidden', borderRadius: '10px'}} container
                  spacing={0}>
                <Grid xs={3}>
                    <Box sx={{
                        backgroundColor: `${color}4D`,
                        height: 'calc(100% - 52px)',
                        padding: '25px 15px',
                        textAlign: 'left',
                        fontSize: '16px',
                        lineHeight: '1.2',
                        borderLeft: `1px solid ${color}`,
                        borderTop: `1px solid ${color}`,
                        borderBottom: `1px solid ${color}`,
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                    }}>
                        <div dangerouslySetInnerHTML={{__html: chapter.name}}/>
                    </Box>
                </Grid>
                <Grid xs={9}>
                    <Box sx={{
                        padding: '0',
                        borderRadius: '0',
                        borderRight: `1px solid ${color}`,
                        borderTop: `1px solid ${color}`,
                        borderBottom: `1px solid ${color}`,
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        height: 'calc(100% - 2px)',
                    }}>
                        {chapter.topics && chapter.topics.map((topic: ITopic, index: number) => {
                            return (
                                <Topic topic={{
                                    ...topic,
                                    index: index,
                                    amount: chapter.topics.length,
                                    color: color,
                                    expandAll: chapter.expandAll
                                }}
                                       addExpandedLesson={addExpandedLesson}
                                       removeExpandedLesson={removeExpandedLesson}
                                       previewType={previewType}
                                       key={topic.name + index}/>
                            )
                        })}
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}