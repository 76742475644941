import * as React from 'react';
import Area from "../../components/Area";
import {Divider} from "@mui/material";
import {IArea, ILevelViewData} from "../../types";

function LevelView({level, previewType}: { level: ILevelViewData, previewType?: string }) {
    return (
        <React.Fragment>
            <Divider/>
            {level.areas.map((area: IArea, index: number) => <Area area={area} previewType={previewType} key={area.name + index}/>)}
        </React.Fragment>
    )
}

export default LevelView