import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Chapter from "../Chapter";
import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {IArea, IChapter, ILesson, ISubtopic, ITopic} from "../../types";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import {haveLessons} from "../../utils";

const useStyles = makeStyles({
    areaName: {
        display: 'inline',
        marginLeft: '10px !important',
        fontSize: '20px !important',
        fontWeight: 'bold !important',
        top: '-5px',
        position: 'relative',
    }
});

export default function Area({area, previewType}: { area: IArea, previewType?: string }) {
    const classes = useStyles(area);
    const [expandAll, setExpandAll] = React.useState(false);
    const [expandedAreaLessons, setExpandedAreaLessons] = React.useState(0);

    const showExpandAll = area.chapters.find(chapter => chapter.topics.find(topic => haveLessons(topic)));

    const addExpandedLesson = () => setExpandedAreaLessons(expandedAreaLessons + 1)
    const removeExpandedLesson = () => setExpandedAreaLessons(expandedAreaLessons - 1)

    let allLessonsCount = 0
    area.chapters.forEach(chapter => chapter.topics.forEach(elem => {
        if ((elem as ITopic).subtopics) {
            elem.subtopics?.forEach(item => allLessonsCount += ((item as ISubtopic).lessons as ILesson[]).length)
        }
        if ((elem as ITopic).lessons) {
            allLessonsCount += ((elem as ITopic).lessons as ILesson[]).length
        }
    }));
    useEffect(() => {
        if (allLessonsCount === expandedAreaLessons && !expandAll) {
            setExpandAll(true)
        }
        if (expandedAreaLessons === 0 && expandAll) {
            setExpandAll(false)
        }
    }, [expandedAreaLessons]);

    useEffect(() => {
        if (!expandAll && expandedAreaLessons !== 0) {
            setExpandedAreaLessons(0)
        }
        if (expandAll && expandedAreaLessons !== allLessonsCount) {
            setExpandedAreaLessons(allLessonsCount)
        }
    }, [expandAll]);
    return (
        <React.Fragment>
            <Box sx={{marginTop: '36px'}}>
                    <span className={`material-symbols-outlined`}>
                        <span style={{
                            // fixme - need to be by style, due to that make styles doesn't work
                            backgroundColor: area.areaColor,
                            width: '40px',
                            height: '40px',
                            color: 'white',
                            borderRadius: '10px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {area.icon}
                        </span>
                    </span>
                <Typography className={classes.areaName}>{area.name}</Typography>
                {showExpandAll && !expandAll && (
                    <KeyboardDoubleArrowDownIcon sx={{position: 'absolute', right: '0px', marginTop: '20px'}}
                                                 onClick={() => {
                                                     setExpandAll(true);
                                                 }}/>)}
                {showExpandAll && expandAll && (
                    <KeyboardDoubleArrowUpIcon sx={{position: 'absolute', right: '0px', marginTop: '20px'}}
                                               onClick={() => {
                                                   setExpandAll(false);
                                               }}/>)}
            </Box>

            {area.chapters.map((chapter: IChapter, index: number) => <Chapter
                chapter={{...chapter, expandAll: expandAll}}
                addExpandedLesson={addExpandedLesson}
                removeExpandedLesson={removeExpandedLesson}
                color={chapter.chapterColor ? chapter.chapterColor : area.areaColor}
                previewType={previewType}
                key={chapter.name + index}/>)}
        </React.Fragment>
    );
}